import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from "redux-saga/effects";
import {
  POST,
  POSTS_GET,
  POSTS_SAVE,
  POST_GET,
 } from "../constants";
import {
  getPostError,
  getPostsError,
  getPostsSuccess,
  getPostSuccess,
  postError,
  postSaveError,
  postSaveSuccess,
  postSuccess
} from "./actions";

// Own
import apiRequest from "../../helpers/apiHelper";
import { setAllRecordsError, setAllRecordsSuccess } from "../actions";

const postRequest = async (payload) => {
  const request = await apiRequest({
    api: 'SavePostData',
    props: payload
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
}

function* post({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(postRequest, payload);
    /* end */
    yield put(postSuccess(response));
  } catch (error) {
    yield put(postError(error));
  }
}

export function* watchPost() {
  yield takeEvery(POST, post);
}

const getPostsListAsync = async (payload) => {
  const {
    postType,
    ecosystemID: ecosystemId,
    page,
    dataValue,
    peerPage,
  } = payload;
  const request = await apiRequest({
    api: 'GetPosts',
    props: {
      filter: {
        postType,
        ecosystemId,
        dataValue,
      },
      page,
      peerPage,
      orderBy: payload?.orderBy ?? null,
    },
  })
  .then((response) => response)
  .catch((error) => error);

  return request;
};

function* getPostsList({payload}) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const result = yield call(getPostsListAsync, payload);
    if (result.status) {
      yield put(getPostsSuccess(result.response ?? []));
      yield put(setAllRecordsSuccess(result['all-records']));
    } else {
      yield put(getPostsError(result.Error));
      yield put(setAllRecordsError(0));
    }
  } catch (error) {
    yield put(getPostsError(error));
    yield put(setAllRecordsError(0));
  }
}

export function* watchGetList() {
  yield takeEvery(POSTS_GET, getPostsList);
}

const postSaveAsync = async ({ fields }) => {
  const request = await apiRequest({
    api: 'SavePostData',
    props: {
      postType: 1,
      postStatus: 1,
      ecosystemID: +fields.ecosystemId,
      fields: {
        title: fields.title,
        actionLine: fields.actionLine,
        dateStart: fields.dateStart,
        dateEnd: fields.dateEnd,
        processPhase: 1,
        ecosystemId: +fields.ecosystemId,
        informationPhaseDetails: fields.informationPhaseDetails,
        informationProcessPhaseDetails: fields.informationProcessPhaseDetails,
        debatePhaseDetails: fields.debatePhaseDetails,
        returnPhaseDetails: fields.returnPhaseDetails,
        trackingPhaseDetails: fields.trackingPhaseDetails,
        fileId: fields.fileId,
        files: fields.files,
      },
    },
  }).catch((error) => error);
  return request;
};

function* postSave({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(
      postSaveAsync,
      payload.data
    );
    if (request.status) {
      yield put(postSaveSuccess(request.status));
    }
    if (!request.status) {
      yield put(postSaveError(request?.Error?.errorMessage ?? 'Error'));
      }
  } catch (error) {
    yield put(postSaveError(error));
  }
}

export function* watchPostSave() {
  yield takeEvery(POSTS_SAVE, postSave);
}

// GET POST
const getPostAsync = async (payload) => {
  const request =  await apiRequest({
    api: 'GetPost',
    props: {
      filter: {
        postId: payload,
      },
    },
  })
    .then((response) => response)
    .catch((error) => error);

  return request;
};

function* getPost({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(getPostAsync, payload);
    if (request.status) {
      yield put(getPostSuccess(request.response[0]));
    } else {
      yield put(getPostError(request.message));
    }
  } catch (error) {
    yield put(getPostError(error));
  }
}

export function* watchGetPost() {
  yield takeEvery(POST_GET, getPost);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetPost),
    fork(watchPost),
  ])
}
