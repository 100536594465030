// Styles
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';

const render = () => {
  import(`./assets/css/sass/themes/gogo.light.purplemonster.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();

