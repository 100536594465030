import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import {
  DELETE_USER,
  USER_LIST_GET_LIST,
  USER_CHANGE_ROL_PETITION_LIST,
} from '../constants';
import {
  changeRolPetitionListError,
  changeRolPetitionListSuccess,
  deleteUserError,
  deleteUserSuccess,
  getUserListError,
  getUserListSuccess,
} from './actions';
import { setAllRecordsError, setAllRecordsSuccess } from '../actions';

export function* deleteUser({payload}) {
  const state = yield select();
  const loading = state?.users?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'DeleteUser',
      props: payload,
    });
    if (request.status) {
      yield put(deleteUserSuccess());
    } else {
      yield put(deleteUserError(request.Error.errorCode));
    }
  } catch (error) {
    console.error(error)
    yield put(deleteUserError(error));
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

const getUserListRequestAsync = async (payload) => {
  const { page = 1, peerPage = 10 } = payload;

  const request = await apiRequest({
    api: 'GetAllUsers',
    props: {
      page,
      peerPage,
    },
  })
    .then((response) => response)
    .catch((error) => error);
  return request;

};

function* getUserListItems({payload}) {
  const state = yield select();
  const loading = state?.users?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(getUserListRequestAsync, payload);

    if (response.status) {
      yield put(getUserListSuccess(response.response));
      yield put(setAllRecordsSuccess(response['all-records']));
    } else {
      yield put(getUserListError('Error obteniendo datos'));
      yield put(setAllRecordsError(0));
    }
  } catch (error) {
    yield put(getUserListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(USER_LIST_GET_LIST, getUserListItems);
}

export function* changeRolPetitionList({payload}) {
  const state = yield select();
  const loading = state?.users?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'ChangeUserRolPetitionList',
      props: payload ?? null,
    });
    if (request.status) {
      yield put(changeRolPetitionListSuccess(request.response));
    } else {
      yield put(changeRolPetitionListError(request.Error.errorCode));
    }
  } catch (error) {
    yield put(changeRolPetitionListError(error));
  }
}

export function* watchChangeRolPetitionList() {
  yield takeEvery(USER_CHANGE_ROL_PETITION_LIST, changeRolPetitionList);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchDeleteUser),
    fork(watchChangeRolPetitionList),
  ]);
}
