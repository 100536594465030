import {
  PUBLICATIONS_GET_LIST,
  PUBLICATIONS_GET_LIST_SUCCESS,
  PUBLICATIONS_GET_LIST_ERROR,
  POST_GET,
  POST_GET_SUCCESS,
  POST_GET_ERROR,
  PUBLICATIONS_GET_LIST_WITH_FILTER,
  PUBLICATIONS_GET_LIST_WITH_ORDER,
  PUBLICATIONS_GET_LIST_SEARCH,
  PUBLICATIONS_ADD_ITEM,
  PUBLICATIONS_ADD_ITEM_SUCCESS,
  PUBLICATIONS_ADD_ITEM_ERROR,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  PUBLICATIONS_ADD_GENERIC_ITEM,
  PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS,
  PUBLICATIONS_ADD_GENERIC_ITEM_ERROR,
  PUBLICATION_SET_STATUS,
  PUBLICATION_SET_STATUS_SUCCESS,
  PUBLICATION_SET_STATUS_ERROR,
} from '../constants';


export const setPostStatus = (payload) => {
  return {
    type:  PUBLICATION_SET_STATUS,
    payload
  }
};

export const setPostStatusSuccess = (payload) => {
  return {
    type:  PUBLICATION_SET_STATUS_SUCCESS,
    payload
  }
};

export const setPostStatusError = (payload) => {
  return {
    type:  PUBLICATION_SET_STATUS_ERROR,
    payload
  }
};

export const getPublicationsList = (payload) => {
  return {
    type: PUBLICATIONS_GET_LIST,
    payload,
  }
};

export const getPublicationsListSuccess = (items) => ({
  type: PUBLICATIONS_GET_LIST_SUCCESS,
  payload: items,
});

export const getPublicationsListError = (error) => ({
  type: PUBLICATIONS_GET_LIST_ERROR,
  payload: error,
});

export const getPost = (data) => ({
  type: POST_GET,
  payload: { data },
});

export const getPostSuccess = (items) => ({
  type: POST_GET_SUCCESS,
  payload: items,
});

export const getPostError = (error) => ({
  type: POST_GET_ERROR,
  payload: error,
});

export const deletePost = (idPost) => ({
  type: DELETE_POST,
  payload:  idPost ,
});

export const deletePostSuccess = ( response ) => ({
  type: DELETE_POST_SUCCESS,
  payload: response,
});

export const deletePostError = (error) => ({
  type: DELETE_POST_ERROR,
  payload: error,
});

export const getPublicationsListWithFilter = (column, value) => ({
  type: PUBLICATIONS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getPublicationsListWithOrder = (column) => ({
  type: PUBLICATIONS_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getPublicationsListSearch = (keyword) => ({
  type: PUBLICATIONS_GET_LIST_SEARCH,
  payload: keyword,
});

export const addPublicationsItem = (item) => ({
  type: PUBLICATIONS_ADD_ITEM,
  payload: item,
});

export const addPublicationsItemSuccess = (items) => ({
  type: PUBLICATIONS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addPublicationsItemError = (error) => ({
  type: PUBLICATIONS_ADD_ITEM_ERROR,
  payload: error,
});

export const addPublicationsGenericItem = (item) => ({
  type: PUBLICATIONS_ADD_GENERIC_ITEM,
  payload: item,
});

export const addPublicationsGenericItemSuccess = (items) => ({
  type: PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS,
  payload: items,
});

export const addPublicationsGenericItemError = (error) => ({
  type: PUBLICATIONS_ADD_GENERIC_ITEM_ERROR,
  payload: error,
});
