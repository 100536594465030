import { all } from 'redux-saga/effects';

// Own
import sagaAuth from './auth/saga';
import sagaPosts from './posts/saga';
import sagaMeeting from './meeting/saga';
import sagaRoles from './roles/saga';
import sageHelp from './contact/saga';
import sagaComments from './comments/saga';
import sagaReactions from './reactions/saga';
import sagaFiles from './fileManager/saga';
import sagaProfile from './profile/saga';
import sagaPreferences from './preferences/saga';
import sagaEcosystems from './ecosystems/saga';
import sagaUsers from './users/saga';
import sagaVotes from './votes/saga';

export default function* rootSaga() {
  yield all([
    sagaAuth(),
    sagaPosts(),
    sagaMeeting(),
    sagaRoles(),
    sageHelp(),
    sagaComments(),
    sagaReactions(),
    sagaFiles(),
    sagaProfile(),
    sagaPreferences(),
    sagaEcosystems(),
    sagaUsers(),
    sagaVotes(),
  ]);
}
