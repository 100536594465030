import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

// Lazy loading
// Under construction
const UnderConstruction = React.lazy(() =>
  import(/* webpackChunkName: "under-construction" */ './pages/statics/under-construction')
);
// Error
const Error = React.lazy(() =>
  import(/* webpackChunkName: "errors" */ './pages/errors')
);

// Layouts
// GuestLayout
const GuestLayout = React.lazy(() =>
  import(/* webpackChunkName: "guest" */ './layouts/guest')
);
// App Layout
const AppLayout = React.lazy(() =>
  import(/* webpackChunkName: "app" */ './layouts/app')
);

// Auth pages
const Login = React.lazy(() =>
  import(/* webpackChunkName: "auth-login" */'./pages/auth/login')
);
const Register = React.lazy(() =>
  import(/* webpackChunkName: "auth-register" */'./pages/auth/register')
);
const ForgotPassword = React.lazy(() =>
  import(/* webpackChunkName: "auth-forgot-password" */ './pages/auth/forgot-password')
);
const UpdatePassword = React.lazy(() =>
  import(/* webpackChunkName: "auth-update-password" */ './pages/auth/update-password')
);

// Static pages
const SmartTerritory = React.lazy(() =>
  import(/* webpackChunkName: "statics-smart-territory" */ './pages/statics/smart-territory')
);
const TouristicDevelop = React.lazy(() =>
  import(/* webpackChunkName: "statics-touristic-development" */ './pages/statics/touristic-development')
);
const GreenTransition = React.lazy(() =>
  import(/* webpackChunkName: "statics-green-transition" */ './pages/statics/green-transition')
);
const BusinessDevelop = React.lazy(() =>
  import(/* webpackChunkName: "statics-business-development" */ './pages/statics/business-development')
);
const RuralRepopulation = React.lazy(() =>
  import(/* webpackChunkName: "statics-rural-repopulation" */ './pages/statics/rural-repopulation')
);
const Home = React.lazy(() =>
  import(/* webpackChunkName: "statics-home" */ './pages/statics/home')
);
const Help = React.lazy(() =>
  import(/* webpackChunkName: "statics-help" */ './pages/statics/help')
);
const Faqs = React.lazy(() =>
  import(/* webpackChunkName: "statics-faqs" */ './pages/statics/faqs')
);
const UserGuide = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/statics/user-guide')
);

// Dashboard pages
const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/dashboard/index')
);

// Collaborative process
const CollaborativeProcess = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/collaborative-process')
);

// Create collaborative process
const CreateCollaborativeProcess = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/collaborative-process/create')
);

// Publications
const Publications = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/publications')
);

// File Manager
const FileManager = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/file-manager')
);

// Profile
const Profile = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/profile')
);

// Preferences
const Preferences = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/preferences')
);

// Notifications
const Notifications = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/notifications')
);

// Users
const Users = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/users')
);

// FAQS user
const FaqsUser = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/faqs')
);

// Collaborative process detail
const CollaborativeProcessDetailView = React.lazy(() =>
  import(/* webpackChunkName: "statics-user-guide" */ './pages/collaborative-process/detail')
);

// Collaborative process detail
const Search = React.lazy(() =>
  import(/* webpackChunkName: "pages-search" */ './pages/search')
);

// TODO: Create dynamic translates routes
const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <UnderConstruction />,
    errorElement: <Error />,
    children: [
    ]
  },
  {
    path: "auth",
    Component: GuestLayout,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate replace to={'login'} />,
      },
      {
        path: "login",
        element: <Login />,
        errorElement: <Error />,
      },
      {
        path: "register",
        element: <Register />,
        errorElement: <Error />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        errorElement: <Error />,
      },
      {
        path: "update-password",
        element: <UpdatePassword />,
        errorElement: <Error />,
      }
    ]
  },
  {
    path: "app",
    Component: AppLayout,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate replace to={'dashboard'} />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement: <Error />,
      },
      {
        path: "profile",
        element: <Profile />,
        errorElement: <Error />,
      },
      {
        path: "preferences",
        element: <Preferences />,
        errorElement: <Error />,
      },
      {
        path: "users",
        element: <Users />,
        errorElement: <Error />,
      },
      {
        path: "notifications",
        element: <Notifications />,
        errorElement: <Error />,
      },
      {
        path: "faqs",
        element: <FaqsUser />,
        errorElement: <Error />,
      },
      {
        path: "files/:id",
        element: <FileManager />,
        errorElement: <Error />,
      },
      {
        path: "publications/:id",
        element: <Publications />,
        errorElement: <Error />,
      },
      {
        path: "collaborative-process/:id",
        element: <CollaborativeProcess />,
        errorElement: <Error />,
      },
      {
        path: "collaborative-process/:id/create",
        element: <CreateCollaborativeProcess />,
        errorElement: <Error />,
      },
      {
        path: "collaborative-process/:id/detail/:detail",
        element: <CollaborativeProcessDetailView />,
        errorElement: <Error />,
      },
      {
        path: "search",
        element: <Search />,
        errorElement: <Error />,
      },
    ],
  },
  // Protected route
  {
    path: "/smart-territory",
    element: <SmartTerritory />,
    errorElement: <Error />,
  },
  {
    path: "/touristic-develop",
    element: <TouristicDevelop />,
    errorElement: <Error />,
  },
  {
    path: "/green-transition",
    element: <GreenTransition />,
    errorElement: <Error />,
  },
  {
    path: "/business-develop",
    element: <BusinessDevelop />,
    errorElement: <Error />,
  },
  {
    path: "/rural-repopulation",
    element: <RuralRepopulation />,
    errorElement: <Error />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/help",
    element: <Help />,
    errorElement: <Error />,
  },
  {
    path: "/faqs",
    element: <Faqs />,
    errorElement: <Error />,
  },
  {
    path: "/user-guide",
    element: <UserGuide />,
    errorElement: <Error />,
  },
]);

export default router;
