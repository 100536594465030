import {
  USER_UPLOAD_PROFILE_PICTURE,
  USER_UPLOAD_PROFILE_PICTURE_SUCCESS,
  USER_UPLOAD_PROFILE_PICTURE_ERROR,
  USER_UPDATE_PROFILE_DATA,
  USER_UPDATE_PROFILE_DATA_SUCCESS,
  USER_UPDATE_PROFILE_DATA_ERROR,
  USER_CHANGE_ROL_PETITION,
  USER_CHANGE_ROL_PETITION_SUCCESS,
  USER_CHANGE_ROL_PETITION_ERROR,
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: null,
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case USER_UPLOAD_PROFILE_PICTURE:
      return {
        ...state,
        loading: true,
        error: null,
        payload: action.payload
      };

    case USER_UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payload: action.payload
      };

    case USER_UPLOAD_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        payload: null
      };

    case USER_UPDATE_PROFILE_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        payload: action.payload,
      };

    case USER_UPDATE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payload: action.payload,
      };

    case USER_UPDATE_PROFILE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        payload: null,
      };

    case USER_CHANGE_ROL_PETITION:
      return {
        ...state,
        loading: true,
        error: null,
        payload: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payload: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        payload: null,
      };


    default:
      return { ...state };
  }
};

export default reducer;
