import { combineReducers } from 'redux';

// Own
import auth from './auth/reducer';
import menu from './menu/reducer';
import posts from './posts/reducer';
import roles from './roles/reducer';
import contact from './contact/reducer';
import records from './records/reducer';
import comments from './comments/reducer';
import reactions from './reactions/reducer';
import votes from './votes/reducers';
import publications from './publications/reducer';
import meeting from './meeting/reducer';
import files from './fileManager/reducer';
import profile from './profile/reducer';
import preferences from './preferences/reducer';
import ecosystems from './ecosystems/reducer';
import users from './users/reducer';
import locales from './locale/reducer';

const reducers = combineReducers({
  auth,
  menu,
  posts,
  roles,
  contact,
  records,
  comments,
  reactions,
  votes,
  publications,
  meeting,
  files,
  profile,
  preferences,
  ecosystems,
  users,
  locales,
});

export default reducers;
