
export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = 'Por Favor Ingresa tu password';
  } else if (value.length < 4) {
    error = 'El valor debe ser mas de 3 caracteres';
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = 'Por favor ingresa tu email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Email inválido';
  }
  return error;
};

export const validateName = (value) => {
  let error;
  if (!value) {
    error = 'Por favor ingresa la informacion';
  } else if (value.length < 3) {
    error = 'debe tener mas de 3 caracteres';
  }
  return error;
};

// const validateEmail = (value) => {
//   let error;
//   if (!value) {
//     error = 'Ingresa tu correo';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
//     error = 'correo invalido';
//   }
//   return error;
// };

// export const validatePassword = (value) => {
//   let error;
//   if (!value) {
//     error = 'Ingresa tu clave';
//   } else if (value.length < 8) {
//     error = 'debe contener mas de 8 caracteres';
//   }
//   return error;
// };

export const validatePasswordConfirm = (value) => {
  let error;
  if (!value) {
    error = 'indroduce nuevamente la clave';
  }
  return error;
};
