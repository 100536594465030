import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_SUCCESS,
  USER_LIST_GET_LIST_ERROR,
  USER_CHANGE_ROL_PETITION_LIST,
  USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
  USER_CHANGE_ROL_PETITION_LIST_ERROR
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: '',
  users:  [],
  petitions:  [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* DELETE USER */
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        postId: action.payload
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // GET USER LIST
    case USER_LIST_GET_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };

    case USER_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case USER_LIST_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

      case USER_CHANGE_ROL_PETITION_LIST:
        return {
          ...state,
          loading: true,
          payload: action.payload
        }

      case USER_CHANGE_ROL_PETITION_LIST_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload
        }

      case USER_CHANGE_ROL_PETITION_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          petitions: action.payload,
        }

    default:
      return { ...state };
  }
};

export default reducer;
