// Own
import { DEFAULT_LOCALE, LOCALES, PAGES_KEYS_TO_PERMISSIONS } from "../constants/globals";

export const extractFields = (value) => {
  const fields = value?.fields?.map((item) => {
    return item;
  });

  const object = {};
  fields.forEach((element) => {
    object[element.key] = element.value;
  });

  return object;
};

export const faqHasTitle = (content) => {
  const test = content?.match(
    /<p><strong>+[^<]+<\/strong><\/p>/gi
  );
  if (!test) {
    return false;
  }
  return true;
}

export const getPermissionsOnPage = (user, location, pageKey) => {
  const objectPermissions = user?.role?.permissions;
  const { pathname } = location;
  let permission = null;
  if (objectPermissions && pathname?.includes(pageKey)) {
    permission = objectPermissions[PAGES_KEYS_TO_PERMISSIONS[pageKey]];
  }

  if (objectPermissions && pathname?.includes(pageKey)) {
    permission = objectPermissions[PAGES_KEYS_TO_PERMISSIONS[pageKey]];
  }
  return permission;
}

export const getFormatDate = ( date ) => {
  const today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}-${mm}-${yyyy}`;
};

export const getDiffTime = (value) => {
  const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
  return -seconds;
}

export const getContentSrc = (attribute) => {
  const value = attribute.match(/"(.+?)"/g)[0];
  return value;
}

export const getContentSrcClean = (src) => {
  const contentSrc = getContentSrc(src);
  const srcClean = contentSrc.replaceAll('"', '');
  return srcClean;
}

export const haveImgHtmlTag = (html) => {
  const value = html?.match(/<img src="(.*?)>/g);
  return value;
}

export const havePHtmlTag = (html) => {
  const value = html?.match(/<p>(.*?)<\/p>/g);
  return value;
}

export const getSrcOfHTMLTag = (tag) => {
  const value = tag.match(/src\s*=\s*"(.+?)"/g)[0];
  return value;
}

export const filterUnique = (array) => {
  const current = [];
  const items = []
  array.forEach((item) => {
    if (!current.includes(item.key)) {
      current.push(item.key);
      items.push(item);
    }
  });
  return items;
}

export const stringToSlug = (value) => {
  let str = value.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  str = removeAccentsDiacritics(str);
  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const removeAccentsDiacritics = (value) => {
  return value?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
}

export const valuesToArray = (obj) => {
  const array = [];
  Object.entries(obj).forEach(([keyy, valuee]) => {
    array.push({ key: keyy, value: valuee });
  });
  return array;
};

export const sortArrayObjectByKey = (array, key) => {
  const newArray = array.sort((a, b) => {
    return a[key] - b[key];
  });
  return newArray;
}

export const camelize = (value) => {
  let str = value?.replaceAll('-', ' ');
  str = str?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
  return str;
}

export const slugToCamelCase = (value) => {
  let key = removeAccentsDiacritics(value);
  key = camelize(key);
  return key;
}

export const filteredFaq = (list) => {
  const items = list.filter(
    (item) => {
      const fieldsLocal = extractFields(item);
      const contentLocal = fieldsLocal?.htmlContent
      const test = faqHasTitle(contentLocal);
      if (!test) {
        return false;
      }
      return true;
    }
  );
  return items;
}

export const getCurrentLanguage = () => {
  let language = DEFAULT_LOCALE;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      LOCALES.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : DEFAULT_LOCALE;
  } catch (error) {
    console.error(error);
    language = DEFAULT_LOCALE;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.error(error);
  }
};
