import {
  SET_ALL_RECORDS,
  SET_ALL_RECORDS_ERROR,
  SET_ALL_RECORDS_SUCCESS
} from "../constants";

const INIT_STATE = {
  allRecords: 0
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case SET_ALL_RECORDS:
      return {
        ...state,
      }

    case SET_ALL_RECORDS_SUCCESS:
      return {
        ...state,
        allRecords: action.payload,
      }

    case SET_ALL_RECORDS_ERROR:
      return {
        ...state,
        allRecords: 0,
      }

    default:
      return { ...state };
  }
}

export default reducer;
