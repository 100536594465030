import React, { Suspense } from 'react';
import { Provider, connect } from 'react-redux';
import {
  RouterProvider,
} from 'react-router-dom';

// React intl
import { IntlProvider } from 'react-intl';

// Own
import router from './routes';
import locale from './lang';
import { DEFAULT_LOCALE } from './constants/globals';

const App = ({
  locale: currentLocale
}) => {
  return (
    <IntlProvider
      locale={currentLocale}
      defaultLocale={DEFAULT_LOCALE}
      messages={locale[currentLocale].messages}
    >
      <Suspense
        fallback={
          <div className="loading" />
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </IntlProvider>
  );
}
const mapStateToProps = ({
  locales
}) => {
  const {
    locale
  } = locales;
  return {
    locale
  };
};

const mapStateToPropsActions = {
}

export default connect(
  mapStateToProps,
  mapStateToPropsActions
)(App);
