export const ROUTE_ROOT = '/';
export const ROUTE_APP = '/app';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_MY_ACCOUNT = '/my-account';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_HOME = '/home';
export const ROUTE_HELP = '/help';
// COLLABORATIVE PROCESS
export const ROUTE_COLLABORATIVE_PROCESS = '/collaborative-process'

// PUBLICATION
export const ROUTE_PUBLICATIONS = '/publications';

// FILES
export const ROUTE_FILES = '/files';

// SEARCH
export const ROUTE_SEARCH = '/search';

// AUTH
export const ROUTE_REGISTER = '/auth/register';
export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_LOGIN = '/auth/login';

// Dashboard
export const ROUTE_APP_DASHBOARD = '/app/dashboard';
//
export const ROUTE_FAGS = '/faqs';
//
export const ROUTE_LOGOUT = '/logout';
//
export const ROUTE_MANAGE_USERS = '/manage-users';
//
export const ROUTE_NOTIFICATIONS = '/notifications';
//
export const ROUTE_PREFERENCES = '/preferences';
//
export const ROUTE_USERS = '/users';
