import apiRequest from '../../helpers/apiHelper';

class FilesUploader {
  constructor() {
    this.CHUNK_MAX_SIZE = 99999;
    this.fileList = [];
    this.fileListId = [];
    this.fileListProcessedSuccess = [];
    this.fileListProcessedFail = [];
    this.filesProcessedSuccess = [];
    this.filesProcessedFail = [];
  }

  setFileList(fileList) {
    this.fileList = fileList;
  }

  async processFiles() {
    await Promise.all(
      this.fileList.map(async (fileData) => {
        const { status, fileId } = await this.uploadFile(fileData);

        if (status) {
          this.setFilesProcessedSuccess(fileData);
        } else {
          this.setFilesProcessedFail(fileData);
        }

        this.fileListId.push(fileId);
        this.progress += 1;
      })
    );
  }

  async uploadFile(fileData) {
    const chunkList = [];
    let chunkInitialPostion = 0;

    while (chunkInitialPostion <= fileData?.dataURL?.length) {
      const chunkEndingPosition = chunkInitialPostion + this.CHUNK_MAX_SIZE;

      chunkList.push(
        fileData.dataURL.substring(chunkInitialPostion, chunkEndingPosition)
      );

      chunkInitialPostion = chunkEndingPosition;
    }

    let fileId = 0;
    let processStatus = true;

    /* eslint-disable no-await-in-loop */
    for (let chunkIndex = 0; chunkIndex < chunkList.length; chunkIndex += 1) {

      if (!processStatus) {
        break;
      }

      const isLastChunk = chunkList.length - 1 === chunkIndex;

      const props = {
        fileName: fileData.fileName,
        fileType: fileData.fileType,
        fileContent: chunkList[chunkIndex],
        fileId,
        lastChunk: isLastChunk,
      };

      const { status, response } = await apiRequest({
        api: 'UploadFile',
        props,
      });

      if (!status) {
        processStatus = false;
        break;
      }

      if (chunkIndex === 0) {
        fileId = response.fileId;
      }
    }

    return {
      fileId,
      status: processStatus,
    };
  }

  setFilesProcessedSuccess(fileData) {
    this.filesProcessedSuccess.push(fileData);
  }

  getFilesProcessedSuccess() {
    return this.filesProcessedSuccess;
  }

  setFilesProcessedFail(fileData) {
    this.filesProcessedFail.push(fileData);
  }

  getFilesProcessedFail() {
    return this.filesProcessedFail;
  }

  getFileListId() {
    return this.fileListId;
  }
}

export default FilesUploader;
