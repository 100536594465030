import React, { useState, useEffect } from 'react';
import {
  Row,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Button,
  FormText,
} from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';

// import { NotificationManager } from 'components/common/react-notifications';


// Own
import { register } from '../../redux/actions';
import { CustomCol } from '../../components/common/CustomBootstrap';
import {
  ROUTE_LOGIN,
  ROUTE_ROOT,
} from '../../constants/routes';

import {
  validatePassword,
  validateEmail,
  validateName,
  validatePasswordConfirm,
} from '../../helpers/forms-utils'
import { GOOGLE_SITE_KEY } from '../../constants/globals';

const Register = ({
  loading,
  error,
  registerAction
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);

  const initialValues = {
    name: '',
    alias: '',
    tipoUsuario: '',
    email: '',
    password: '',
    passwordConfirm: '',
  };

  const handleCaptchaChange = () => {
    setVerified(true)
  };

  const handleValidate = (values) => {
    const errors = {};
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'las claves deben coincidir ';
    }
    return errors;
  };

  useEffect(() => {
    if (error) {
      // TODO: Implement after
      // NotificationManager.warning(
      //   error,
      //   intl.formatMessage({ id: 'reset-password.register-error' }),
      //   3000,
      //   null,
      //   null,
      //   ''
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleRegister = (values) => {
    if (!loading) {
      registerAction(values, navigate);
    }
  };

  return (
    <Row className="h-100">
      <CustomCol
        xxs="12"
        md="10"
        className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="text-white h2">
              {intl.formatMessage({id:'pages.register'}).toUpperCase()}
            </p>
            <p className="white mb-0">
              {intl.formatMessage({id:'pages.register-text-1'})}<br />
              {intl.formatMessage({id:'pages.if-you-already-have-an-account'})}&nbsp;
              <NavLink to={ROUTE_LOGIN} className="white">
                {intl.formatMessage({id:'pages.log-in-here'})}
              </NavLink>
              .
            </p>
          </div>
          <div className="form-side">
            <NavLink to={ROUTE_ROOT} className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              {intl.formatMessage({id:'pages.register'})}
            </CardTitle>
            <Formik
              validate={handleValidate}
              initialValues={initialValues}
              onSubmit={handleRegister}
            >
              {({errors,touched}) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <FormGroup className="form-group has-float-label  mb-4">
                    <Label>
                      {intl.formatMessage({id:'pages.fullName'})}
                    </Label>
                    <Field
                      className="form-control"
                      name="name"
                      validate={validateName}
                    />
                    {errors.name && touched.name && (
                      <div className="invalid-feedback d-block">
                        {errors.name}
                      </div>
                    )}
                    <FormText color="muted">
                      {intl.formatMessage({id:'pages.fullName-under-text'})}
                      <br />
                      {intl.formatMessage({id:'pages.fullName-under-text-2'})}
                    </FormText>
                  </FormGroup>
                  <FormGroup className="form-group has-float-label  mb-4">
                    <Label>
                      {intl.formatMessage({id:'pages.email'})}
                    </Label>
                    <Field
                      className="form-control"
                      name="email"
                      validate={validateEmail}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback d-block">
                        {errors.email}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group has-float-label  mb-4">
                    <Label>
                      {intl.formatMessage({id:'pages.password'})}
                    </Label>
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                      validate={validatePassword}
                    />
                    {errors.password && touched.password && (
                      <div className="invalid-feedback d-block">
                        {errors.password}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="form-group has-float-label  mb-4">
                    <Label>
                      {intl.formatMessage({id:'pages.password-confirm'})}
                    </Label>
                    <Field
                      className="form-control"
                      type="password"
                      name="passwordConfirm"
                      validate={validatePasswordConfirm}
                    />
                    {errors.passwordConfirm && touched.passwordConfirm && (
                      <div className="invalid-feedback d-block">
                        {errors.passwordConfirm}
                      </div>
                    )}
                  </FormGroup>

                 {/*  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                      type="submit"
                    >
                      {intl.formatMessage({ id: user.register-button" />
                    </Button>
                  </div> */}

                  <div className="d-flex justify-content-between align-items-center">
                    <ReCAPTCHA
                      sitekey={GOOGLE_SITE_KEY}
                      onChange={handleCaptchaChange}
                      onExpired={() => setVerified(false)}
                    />
                    <div className="d-flex justify-content-end align-items-center">
                      {verified ?
                        <Button
                          color="primary"
                          className={`btn-shadow btn-multiple-state ${loading ? 'show-spinner' : ''
                            }`}
                          size="lg"
                          type='submit'
                        >
                          <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                          </span>
                          <span className="label">
                            {intl.formatMessage({ id:'pages.register'}).toUpperCase()}
                          </span>
                        </Button>
                        : null
                      }
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </CustomCol>
    </Row>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loading, error } = auth;
  return { loading, error };
};
export default connect(mapStateToProps, {
  registerAction: register,
})(Register);
